import { graphql } from 'gatsby';
import Integrations from 'routes/integrations/Integrations';

export default Integrations;

export const query = graphql`
  query Integrations ($skip: Int!, $limit: Int!, $category: String!) {
    page: contentfulPageIntegrations {
      metaDescription
      heroTitle
      metaImage {
        ...image
      }
      heroText: heroBody {
        text: heroBody
      }
      slug
    }

    all: allContentfulAppNew(sort: { fields: label }) {
      edges {
        node {
          label
          url
          body {
            body
          }
          image {
            ...image
          }
          categoryNew {
            name
          }
        }
      }
    }

    filtered: allContentfulAppNew(
      filter: { categoryNew : { name : { eq: $category } } },
      limit: $limit,
      skip: $skip,
      sort: { fields: label },
    ) {
      edges {
        node {
          label
          url
          body {
            body
          }
          image {
            ...image
          }
        }
      }
    }

    unfiltered: allContentfulAppNew(
      limit: $limit,
      skip: $skip,
      sort: { fields: label },
    )  {
      edges {
        node {
          label
          url
          body {
            body
          }
          image {
            ...image
          }
        }
      }
    }

    allContentfulAppCategory(sort: { fields: name }) {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`;
