import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import _get from 'lodash/get';

import extractMeta from 'utils/extract-meta';
import { withUIContext } from 'context/ui';
import HeroIntro from 'components/hero-intro';
import ContentGrid from 'components/content-grid';
import SEO from 'components/seo';
import { Container } from 'components/layout';
import { DocumentNavigation, DocumentNavigationItem } from 'components/document-group';

import Pagination from 'components/pagination';
import s from './Integrations.scss';

class Integrations extends PureComponent {

  static propTypes = {
    data: PropTypes.object.isRequired,
    pageContext: PropTypes.object,
    location: PropTypes.object,
  }

  render() {
    // console.log('Page data: ', this.props.data);

    const {
      page,
      all,
      filtered,
      unfiltered,
      allContentfulAppCategory: { edges: categories },
    } = this.props.data;
    const { pathname } = this.props.location;

    const {
      currentPage,
      numberOfPages,
      pathBase,
      category: currentCategory,
    } = this.props.pageContext;

    const integrations = filtered && filtered.edges && filtered.edges.length > 0 ? filtered : unfiltered;

    const cards = integrations.edges.map(app => ({
      heroImage: app.node.image,
      title: app.node.label,
      subTitle: app.node.section,
      bodyText: app.node.body.body,
      ctaUrl: app.node.url,
    }));

    // console.log(all);

    const shouldAnimate = currentPage === 1;

    return (
      <div>
        <SEO pathname={pathname} article htmlAttributes={{ class: 'smooth-scroll' }} {...extractMeta(page)}/>
        <HeroIntro
          heading={page.heroTitle}
          text={page.heroText.text}
          cancelHr
          shouldAnimate={shouldAnimate}
        />
        <Container>
          <div className={s.flex}>
            <div className={s.left}>
              <DocumentNavigation>
                <DocumentNavigationItem
                  key={0}
                  index={0}
                  text="All"
                  href={`/${page.slug}`}
                  active={!currentCategory}
                />
                {
                  categories
                    .filter((categoryItem) => {
                      const hasAtLeastOneItem =
                        all.edges.reduce((accumulator, currentValue) => {
                          const isSameCategory = currentValue.node.categoryNew.name === categoryItem.node.name;

                          return (accumulator || isSameCategory);
                        }, false);

                      return hasAtLeastOneItem;
                    })
                    .map((categoryItem, i) =>

                      // console.log('Category info: ', categoryItem);
                     (
                       <DocumentNavigationItem
                         href={`/${page.slug}/${categoryItem.node.slug}`}
                         key={categoryItem.node.slug}
                         index={i + 1}
                         text={categoryItem.node.name}
                         active={categoryItem.node.name === currentCategory}
                       />
                    ))
                }
              </DocumentNavigation>
              <Pagination
                currentPage={currentPage}
                numberOfPages={numberOfPages}
                pathBase={pathBase}
              />
            </div>
            <ContentGrid
              cards={cards}
              transition="none"
              noRatio
              shouldAnimate={shouldAnimate}
            />
          </div>
        </Container>
      </div>
    );
  }

} export default withUIContext(Integrations);
